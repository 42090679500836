import React from "react"
import { graphql } from "gatsby"
import { compose } from "ramda"
import { getMarkdownEdges, getQueryData, getSectionDataFor } from "@utils/utils"

/**
 * import Sections
 */
import MainLayout from "@layouts/MainLayout/MainLayout"
import HeaderSection from "@components/shared/HeaderSection/HeaderSection"

/**
 * import pictures
 */

import imagePageNotFound from "@assets/pictures/buehne_fehlerseit.svg"

/**
 * quering data
 */

export const query = graphql`
  query HeaderSectionErrorQuery {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          path: { eq: "/page-not-found" }
          published: { eq: true }
          lang: { eq: "de" }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            path
            subtitle
            title
            section
            cta
            ctaUrl
          }
          internal {
            content
          }
        }
      }
    }
  }
`

const PageNotFound = ({ data }) => {
  const content = compose(getQueryData, getMarkdownEdges)(data)

  const headerSectionData = getSectionDataFor(
    "page-not-found-section-header",
    content
  )

  return (
    <MainLayout path="/">
      <HeaderSection data={headerSectionData} image={imagePageNotFound} />
    </MainLayout>
  )
}

export default PageNotFound
